<div *ngIf="activeQuestion"
     class="question-container">
  <div class="question-media" *ngIf="activeQuestion.image as image">
    <img [src]="image" alt="Question image">
  </div>
  <div class="question-media" *ngIf="activeQuestion.video as video">
    <app-video-replay [video]="video"
                      [useCenteredVideo]="false"
                      [useCustomControls]="true">
    </app-video-replay>
  </div>
  <div class="question"
    [ngClass]="{'personality-test': quizType === QUIZ_TYPES.PERSONALITY_TEST}">
    {{ activeQuestion.text }}
    <div class="info" *ngIf="activeQuestion.questionType === TYPE_OF_SKILLS_QUESTION.multipleAnswersQuestion">
      ({{ 'BUSINESS_TEST.MAX_NUMBER_OF_ANSWERS' | translate: {maxNumber: activeQuestion.maxNumberOfAnswersCandidateCanChoose} }})
    </div>
  </div>
  <div *ngIf="quizType !== QUIZ_TYPES.PERSONALITY_TEST" class="answers-container">
    <ul class="answers-list">
      <li
        *ngFor="let answer of activeQuestion.answers; let i = index"
        class="list-item"
      >
        <button
          (click)="selectAnswer(i)"
          [ngClass]="{ active: activeQuestion.questionType === TYPE_OF_SKILLS_QUESTION.multipleAnswersQuestion ? selectedAnswers.includes(i) : i === selectedAnswer }"
          [disabled]="!enableButtons"
          class="btn-answer"
        >
          {{ answer.text }}
        </button>
      </li>
    </ul>
  </div>
  <div class="button-container"
       *ngIf="activeQuestion.questionType === TYPE_OF_SKILLS_QUESTION.multipleAnswersQuestion">
    <button (click)="onSubmitBusinessQuestionAnswer()"
            class="btn-primary">
      {{ 'BUTTONS.NEXT' | translate }}
    </button>
  </div>

  <div *ngIf="quizType === QUIZ_TYPES.PERSONALITY_TEST" class="personality-test">
    <div class="answers-container">
      <ul class="answers-list">
        <li *ngFor="let answer of personalityTestAnswers" class="list-item">
          <button
            (click)="selectAnswer(answer)"
            [ngClass]="{ active: answer === selectedAnswer }"
            [disabled]="!enableButtons"
            class="btn-answer"
          >
            {{ answer + 1 }}
          </button>
        </li>
      </ul>
      <div class="personality-test-labels">
        <span class="label">{{ PERSONALITY_TEST_LABELS.NOT_CORRECT_AT_ALL[personalityTestLanguage] }}</span>
        <span class="label">{{ PERSONALITY_TEST_LABELS.NEUTRAL[personalityTestLanguage] }}</span>
        <span class="label">{{ PERSONALITY_TEST_LABELS.THATS_RIGHT[personalityTestLanguage] }}</span>
      </div>
    </div>
  </div>
</div>
