import { QUIZ_TYPES } from 'src/app/resources/quiz-types';
import { SetupService } from 'src/app/services/setup.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { JobApplication } from 'src/app/models/job-application.model';
import { ToastrService } from 'ngx-toastr';
import { QuizService } from 'src/app/services/quiz.service';
import { Component, OnInit, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { QuizStoreService } from 'src/app/services/quiz-store.service';
import { formatPersonalityTestQuestions, setQuizTypeQueryParam } from 'src/app/shared-functions';
import { TranslateService } from '@ngx-translate/core';
import { PersonalityTest } from 'src/app/models/personality-test.model';

@Component({
  selector: 'app-quiz-personality-test-start',
  templateUrl: './quiz-personality-test-start.component.html',
  styleUrls: ['./quiz-personality-test-start.component.scss'],
})
export class QuizPersonalityTestStartComponent implements OnInit {

  @HostBinding('class.route-card') card = true;
  @HostBinding('class.first-card') firstCard = true;

  companyName: string;
  candidateName: string;

  readonly FIRST_QUESTION_INDEX = 0;

  constructor(
    private router: Router,
    private quizService: QuizService,
    private quizStore: QuizStoreService,
    private configService: AppConfigService,
    private setupService: SetupService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    const jobApplication = this.configService.config.jobApplication;
    const activeModuleIndex = jobApplication.currentQuestionsModule;
    const activeModule = jobApplication.orderOfQuestionsModules[activeModuleIndex];

    if (activeModule !== QUIZ_TYPES.PERSONALITY_TEST) {
      this.navigateTo('/quiz');
    } else {
      this.companyName = this.configService.organization.name;
    }
  }

  keepResults(): void {
    this.quizService
      .keepPersonalityTestResults()
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => {
          const error = this.translateService.instant(
            'SHARED.TOAST_ERROR_SMT_WENT_WRONG'
          );
          this.toastr.error(error);
          return throwError(() => errorResponse);
        })
      )
      .subscribe((jobApplication: JobApplication) => {
        this.configService.config.jobApplication = { ...jobApplication };

        if (jobApplication.applicationComplete) {
          this.navigateTo('/application-complete');
        } else {
          this.navigateToNextModule(jobApplication);
        }
      });
  }

  takeTest(): void {
    this.quizService.getPersonalityTest()
      .subscribe((questions: PersonalityTest) => {
        const modulesOrder = this.configService.config.jobApplication.orderOfQuestionsModules;
        const personalityTestModuleIndex = modulesOrder
          .findIndex((moduleName: string) => moduleName === QUIZ_TYPES.PERSONALITY_TEST);
        this.configService.config.jobApplication.currentQuestionsModule = personalityTestModuleIndex;

        this.quizStore.quiz = {
          type: QUIZ_TYPES.PERSONALITY_TEST,
          questions: formatPersonalityTestQuestions(questions.content),
          activeQuestionIndex: this.FIRST_QUESTION_INDEX,
        };
        this.configService.config.job.psykometrikaLanguage = questions.language;
        const quizTypeParam = setQuizTypeQueryParam(QUIZ_TYPES.PERSONALITY_TEST);
        this.navigateTo(`/quiz/question/${this.quizStore.activeQuestionIndex}/${quizTypeParam}`);
      });
  }

  navigateToNextModule(jobApplication: JobApplication): void {
    const nextModuleName = jobApplication.orderOfQuestionsModules[jobApplication.currentQuestionsModule];

    if (nextModuleName === QUIZ_TYPES.BUSINESS_QUESTIONS) {
      this.navigateToBusinessQuestions(jobApplication);
    } else if (nextModuleName === QUIZ_TYPES.VIDEO_QUESTIONS) {
      this.navigateToVideoQuestions(jobApplication);
    }
  }

  navigateToBusinessQuestions({ businessQuestions }: JobApplication): void {
    this.quizStore.quiz = {
      type: QUIZ_TYPES.BUSINESS_QUESTIONS,
      questions: businessQuestions.questions,
      rejectionMessage: businessQuestions.rejectionMessage,
      rejectionMessageMain: businessQuestions.rejectionMessageMain,
      activeQuestionIndex: this.FIRST_QUESTION_INDEX,
    };

    const quizTypeParam = setQuizTypeQueryParam(QUIZ_TYPES.BUSINESS_QUESTIONS);
    this.navigateTo(`/quiz/question/${this.quizStore.activeQuestionIndex}/${quizTypeParam}`);
  }

  navigateToVideoQuestions({ videoQuestions }: JobApplication): void {
    this.quizStore.quiz = {
      type: QUIZ_TYPES.VIDEO_QUESTIONS,
      video: videoQuestions.video,
      q1: videoQuestions.q1,
      q2: videoQuestions.q2,
      q3: videoQuestions.q3,
      activeQuestionIndex: this.FIRST_QUESTION_INDEX,
    };
    this.navigateTo('/video');
  }

  navigateTo(route: string): void {
    this.router.navigate([route], { queryParamsHandling: 'merge' });
  }
}
