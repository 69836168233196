<header>
  <h3 class="title">{{ 'PERSONALITY_TEST.GREETING' | translate }}</h3>
</header>
<div class="content">
  <p>
    {{ 'PERSONALITY_TEST.EXPLANATION_COMPANY_1' | translate }}
    {{ companyName }}
    {{ 'PERSONALITY_TEST.EXPLANATION_COMPANY_2' | translate }}</p>
  <p>{{ 'PERSONALITY_TEST.EXPLANATION_REUSE' | translate }}</p>
  <p>{{ 'PERSONALITY_TEST.EXPLANATION_RETAKE' | translate }}</p>
</div>
<div class="controls">
  <button (click)="keepResults()" class="btn-secondary">{{ 'BUTTONS.USE' | translate }}</button>
  <button (click)="takeTest()" class="btn-secondary">{{ 'BUTTONS.REDO' | translate }}</button>
</div>
