export const PERSONALITY_TEST_LABELS = {
  NOT_CORRECT_AT_ALL: {
    en: 'Not correct at all',
    sv: 'Stämmer inte alls',
    no: 'Ikke riktig i det hele tatt',
    da: 'Helt forkert',
    de: 'Trifft überhaupt nicht zu',
    fi: 'Ei pidä lainkaan paikkaansa'
  },
  NEUTRAL: {
    en: 'Neutral',
    sv: 'Neutral',
    no: 'Nøytral',
    da: 'Neutral',
    de: 'Neutral',
    fi: 'Neutraali'
  },
  THATS_RIGHT: {
    en: 'That\'s right',
    sv: 'Stämmer till fullo',
    no: 'Fullstendig korrekt',
    da: 'Det er rigtigt',
    de: 'Trifft voll zu',
    fi: 'Pitää täysin paikkansa'
  }
};
